<template>
  <div class="w-full pb-2 border-b border-gray-200 dark:border-gray-700">
    <div class="w-full flex flex-wrap mb-4 items-end">
      <div class="mb-auto mr-4">
        <Checkbox v-model="form.isSelected" @change="toggleItemIsSelected" /> 
      </div>

      <div class="mr-12 w-72">
        <Input name="description" placeholder="Nosaukums" v-model="form.description" />
      </div>

      <ItemText title="Preces kods" :text="form.sku" />
      <span class="cursor-pointer" @click="openOrder(item.order)">
        <ItemText title="Pasūtījums" :text="form.order.uuid" />
      </span>
    </div>

    <div class="w-full flex flex-wrap mb-4 items-end pl-12">
      <ItemText title="Pasūtījums datums" :text="form.start_date" />
      <ItemText title="Cena/Eur" :text="item.price ? item.price : 'Nav cena'" />
      <ItemText title="Daudzums" :text="form.quantity" />
      <ItemText title="Kopā EUR" :text="form.total" />

      <div class="mr-12 w-32">
        <Input type="number" name="discount" placeholder="Atlaide %" v-model="form.discount" @change="calculateTotal" />
      </div>

      <ItemText title="Summa EUR" :text="+form.total_with_discount.toFixed(2)" />

      <div class="mr-12 w-32">
        <Input type="number" name="vat_rate" placeholder="PVN %" v-model="form.vat_rate" @change="calculateTotal" />
      </div>
    </div>
  </div>
</template>

<script>
import Calculations from "@/services/Calculations/calculations";
import Checkbox from "@/components/Components/Checkbox"
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input"

export default {
  name: "InvoiceableOrderItem",
  components: {
    Checkbox,
    ItemText,
    Input,
  },
  props: ['item', 'toggleItemIsSelected', 'recalculateTotals'],
  data: () => ({
    form: null,
  }),
  created() {
    this.form = this.item
    this.calculateTotal()
  },
  methods: {
    calculateTotal() {
      this.form.total_with_discount = +Calculations.getPriceWithDiscount(this.form.total, this.form.discount)
      this.form.vat = (Calculations.getVat(this.form.total_with_discount, this.form.vat_rate)).toFixed(2)
      this.form.total_with_vat = ((this.form.total_with_discount * 1) + +this.form.vat).toFixed(2)
      this.recalculateTotals()
    },
    openOrder(order) {
      let routeData = this.$router.resolve({path: '/orders/' + order.id});
      window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style>

</style>